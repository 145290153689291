<template>
  <div class="sm:mx-5  lg:mx-20%">
    <da-patient-form></da-patient-form>
  </div>
</template>

<script>
import PatientForm from './PatientForm.vue';

export default {
    components: {
        'da-patient-form': PatientForm
    }
}
</script>

<style>

</style>